import { Injectable } from '@angular/core';
import { createSelector } from 'reselect';

import { State } from '@core/reducers';
import * as fromImportHistoryLogs from '@core/reducers/import-history-logs.reducer';

@Injectable()
export class ImportHistoryLogsSelectors {
    /**
     * Get state.workflows
     */
    getImportHistoryState() {
        return ($state: State): any => $state.importHistoryLogs;
    }

    /**
     * Get list from state.importHistoryLogs
     */
    getHistoryList() {
        return createSelector(
            this.getImportHistoryState(),
            fromImportHistoryLogs.getList,
        );
    }

    /**
     * Get log from state.importHistoryLogs
     */
    getHistoryLog() {
        return createSelector(
            this.getImportHistoryState(),
            fromImportHistoryLogs.getLog,
        );
    }
}
