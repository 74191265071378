import * as importHistoryLogsActions from '@core/actions/import-history-logs.actions';
import { ImportHistoryLog, ImportHistoryLogsList } from '@core/models';

export interface State {
    list: ImportHistoryLogsList;
    log: ImportHistoryLog;
}

const initialState: State = {
    list: {
        data: {
            count: null,
            limit: null,
            offset: null,
            next: null,
            previous: null,
            results: [],
        },
        errors: {},
        isFetching: true,
    },
    log: {
        data: {
            company: '',
            created: '',
            failedProductsCount: null,
            filesUploadCount: null,
            finished: '',
            id: null,
            imagesUploadedCount: null,
            importFile: '',
            importFileName: '',
            mediaUploadType: '',
            productCategory: null,
            status: '',
            successfullyImportedProductsCount: null,
            user: '',
            videosUploadedCount: null,
        },
        errors: {},
        isFetching: true,
    },
};

export function reducer(state = initialState, action: importHistoryLogsActions.Actions) {
    switch (action.type) {
        case importHistoryLogsActions.ActionTypes.GET_LIST_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case importHistoryLogsActions.ActionTypes.GET_LIST_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case importHistoryLogsActions.ActionTypes.GET_LIST_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case importHistoryLogsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case importHistoryLogsActions.ActionTypes.GET_LOG_REQUEST: {
            return {
                ...state,
                log: {
                    ...state.log,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case importHistoryLogsActions.ActionTypes.GET_LOG_SUCCESS: {
            return {
                ...state,
                log: {
                    ...state.log,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case importHistoryLogsActions.ActionTypes.GET_LOG_ERROR: {
            return {
                ...state,
                log: {
                    ...state.log,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case importHistoryLogsActions.ActionTypes.CLEAR_LOG: {
            return {
                ...state,
                log: {
                    ...initialState.log,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;
export const getLog = (state: State) => state.log;
