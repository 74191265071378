import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Import History Logs]';

export const ActionTypes = {
    GET_LIST_REQUEST: type(`${ACTION_PREFIX} Get List Request`),
    GET_LIST_SUCCESS: type(`${ACTION_PREFIX} Get List Success`),
    GET_LIST_ERROR: type(`${ACTION_PREFIX} Get List Error`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),

    GET_LOG_REQUEST: type(`${ACTION_PREFIX} Get Logs Request`),
    GET_LOG_SUCCESS: type(`${ACTION_PREFIX} Get Logs Success`),
    GET_LOG_ERROR: type(`${ACTION_PREFIX} Get Logs Error`),
    CLEAR_LOG: type(`${ACTION_PREFIX} Clear Logs`),
};

/**
 * Create 'Get List Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetListRequestAction implements Action {
    type = ActionTypes.GET_LIST_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get List Succes' Action
 * @param payload Contains object with workflow history logs list and params
 */
export class GetListSuccessAction implements Action {
    type = ActionTypes.GET_LIST_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get List Error' Action
 * @param payload Contains object with errors
 */
export class GetListErrorAction implements Action {
    type = ActionTypes.GET_LIST_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Log Request' Action
 * @param payload Contains object with list params
 */
export class GetLogRequestAction implements Action {
    type = ActionTypes.GET_LOG_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Log Success' Action
 * @param payload Contains object with single import history log and params
 */
export class GetLogSuccessAction implements Action {
    type = ActionTypes.GET_LOG_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Log Error' Action
 * @param payload Contains object with errors
 */
export class GetLogErrorAction implements Action {
    type = ActionTypes.GET_LOG_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Log' Action
 */
export class ClearLogAction implements Action {
    type = ActionTypes.CLEAR_LOG;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetListRequestAction
    | GetListSuccessAction
    | GetListErrorAction
    | ClearListAction
    | GetLogRequestAction
    | GetLogSuccessAction
    | GetLogErrorAction
    | ClearLogAction;
